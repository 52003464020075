
//---- basic drop-down styling ----
.iron-dropdown {
    ul{
      width:12.5rem;
      a{
         i{
            padding-right:10px;
         }
      }
      li{
        height: auto;
        text-transform: capitalize;
       
        .img-wrap{
          height:1.5rem;
          width:1.5rem;
        }
      }
      a:focus,a:hover{
        color:$dark;
      }
    }
}
.iron-header-wrapper{
	transform: none !important;
}
//---- base font and color for dropdown ----
.base-text{
  span{
    font-size:0.875rem;
    color:$base;
    text-transform: capitalize;
  }
  i{
    color:$base;
    margin-left:0.3125rem;
    font-size:1.5rem;
  }
  &:hover{
    background-color: $transparent !important;
  }
}

//---- logo and text styling ----
.iron-app-logo{
  h2{
    font-size:2.125rem;
  }
}
.iron-language-provider,.iron-currency-provider {
  .base-text{
    span{
      font-weight: 400;
    }
  }
}
//---- cart-wishlist btn styling ----
.icon-btn{
  width:2.5rem !important;
  height:2.5rem !important;
  background-color: $grey3 !important;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.18);
  display: flex !important;
  display: -ms-flexbox !important;
  justify-content: center !important;
  -ms-flex-pack: center !important;
   align-items: center !important;
   -ms-flex-align: center !important;
  padding: 0 !important;
  cursor:pointer;
  i{
    font-size: 1.375rem;
    color:$base;
  }
  &:hover{
    background-color: $primary !important;
  }
}

//-----menu icon style----
.menu-icon-wrap{
   background-color: $transparent;
  i{
    color:$base;
  }
}

//----cart and wishlist section styling-----
.iron-cart-list-wrap,.iron-wish-list-wrap {
  width: 18.75rem;
  display: block !important;
   max-height: 320px;
   overflow-x: hidden;
   overflow-y: auto;
  .cart-menu-item {
    border-bottom: 1px solid $border;
    list-style: none;
    .cart-thumb {
      width: 60px;
      display: flex;
      display: -ms-flexbox;
      justify-content: center;
      -ms-flex-pack: center;
      align-items: center;
      -ms-flex-align: center;
    }
    .cart-content-wrap {
      width: calc(100% - 60px);
      .cart-content{
        padding-left:0.9375rem;
        width:55%;
      }
      .cart-edit-action {
        width:calc(100% - 45%);
        .button.icon-btn{
          opacity:1;
          visibility: visible;
          transition: all 0.3s ease-in-out 0s ;
          min-width: 2rem !important;
          min-height: 2rem  !important;
          width: 2.1rem !important;
          height: 2.1rem  !important;
          border-radius: 100%  !important;
          padding: 0  !important;
          background-color: $danger !important;
          i{
            font-size:1.3rem;
          }
        }
      }
    }
    &:hover{
      .cart-content-wrap{
        .cart-edit-action{
          .button.icon-btn{
            opacity:1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.iron-wish-list-wrap{
  .cart-menu-item {
    .cart-content-wrap {
      width: 80%;
      .cart-content{
        width:75%;
      }
      .cart-edit-action {
        width:calc(100% - 75%);
      }
    }
  }
}
//----- horizontal-menu style -------
.iron-header-menu{
   li{
      padding: 0 0.625rem;
      height:auto;
      display:inline-block;
      position: relative;
      a{
         line-height: 2.6rem;
         font-size: .75rem;
         font-weight: 500;
         letter-spacing: 0.5px;
         position:relative;
         padding:0 0.9375rem;
         display: inline-block;
         
         
          &:hover{
            //color:$paolo-veronese-green!important;
            transition: transform 100.1s ease-out;            
            transform-origin: 50%;

          }

        //  &:after{
      
        //  content: '';
        //  height: 5px;
        // background: $orchid-pink;
        //  display: block;
        //  position: absolute;
        //  bottom:0;
        //  left:0;
        //  right:0;
        //  transition: transform 0.3s ease-out;
        //  transform: scaleX(0);
        //  transform-origin: 50%;
        //  }

         
      }
      .sub-menu {
         position: absolute;
         top:100%;
         opacity: 0;
         visibility:hidden;
         z-index: 99;
         transition: transform 0.3s ease-out 0s;
         transform:translateY(50px);
         width: 14rem;
         background-color: $base;
         box-shadow: $box-shadow;
         li {
            display: block;
            padding:0;
            a{
               color:#3a405b;
               line-height:2.8125rem;
               text-align:left;
               display: block;
               &:after{
                  display: none;
               }
               &:hover{
                  color:#3a405b;
               }
            }
            .sub-menu{
                  left:100%;
                  top:0;
            }
               >.sub-menu-child{
                  position: absolute;
                  top:0;
                  right:100%;
                  opacity: 0;
                  visibility:hidden;
                  z-index: 99;
                  transition: transform 0.3s ease-out 0s;
                  transform:translateY(50px);
                  width: 14rem;
                  background-color: $base;
                  box-shadow: $box-shadow;
               }
         }
      }
      &:first-child{
         padding-left: 0;
      }
      &:hover{
         background-color: $transparent;
         a:after{
               transform: scaleX(1);
         }
         >.sub-menu{
               transform: translateY(0);
               opacity:1;
               visibility:visible;
               >li{
                  >.sub-menu{
                     opacity:1;
                     visibility:visible;
                  }
                  &:hover{
                     >.sub-menu-child{
                        transform: translateY(0);
                        opacity:1;
                        visibility:visible;
                     }
                  }
               }
         }
      }
   }
   >li{
      &:last-child{
         padding-right: 0;
        //  a{
        //     background-color: $active;
        //  }
      }
   }
   .mega-menu {
      position: static;
      > .sub-menu {
         width: 64rem;
         margin: 0 auto;
         left: 0;
         right: 0;
         overflow: hidden;
         padding: 3rem;
         li {
            border: none;
         }
         >li {
            display: inline-block;
            width: 25%;
            >a {
               color: $primary;
               border-bottom:1px solid #d5d5d5;
               font-size: 1.25rem;
            }
            >.sub-menu {
               position: static;
               transform: none;
               box-shadow: none;
               li{
                  a{
                     position: relative;
                     padding-left: 1.875rem;
                     text-transform: capitalize;
                     &:before {
                        content: "-";
                        position: absolute;
                        left: 0.9375rem;
                     }
                  }
               }
            }
         }
      }
   }
}

//-----Search box styling-----
.position-relative {
  position: relative;
}
.iron-search-box {
  position: absolute;
  right: 0;
  bottom: 0;
  .search-icon {
    z-index:9;
    position: absolute;
    right: 0;
    bottom: -1.5625rem;
    height: 3.5rem;
    width: 3.5rem;
    background-color: $active;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.12),
    0 6px 6px 0 rgba(0,0,0,0.24);
    &:hover{
      background-color: $active;
    }
  }
  .search-form {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $base;
    input{
      font-size:2rem;
      line-height: 1.3;
    }
    input::placeholder{
      color:$dark !important;
      opacity: 0.6;
    }
    .input-wrap{
      width:calc(100% - 40px);
    }
  }
  .close-btn{
    height: auto;
    width: auto;
    padding: 0;
    &:hover{
      background-color: $transparent;
    }
  }
}

.active-search-form{
  left:0;
  top:0;
  .search-icon {
    display:none;
  }
  .search-form {
    display: flex ;
    display: -ms-flexbox;
  }
}

//search form v2
.search-form-v2{
  width:100%;
  .input-field{
    width: 96%;
    position: relative;
    &:before{
      font-family: 'Material Icons';
      content: "search";
      position: absolute;
      left: 10px;
      top: 0;
      color:$base;
      font-size:1.25rem;
      line-height: 1.8;
      pointer-events: none;
    }
    input{
      background: rgba(255,255,255,.27);
      border: 0;
      color:$base;
      min-height: 35px;
      font-size:0.875rem;
      border-radius: 5px;
      padding: 0;
      padding:0 0.75rem 0 2.5rem;
    }
    input::placeholder{
      color:$base !important;
      opacity: 0.6;
    }
  }
}
.search-form-v3{
  .input-field{
      padding:6px 0;
      border: 1px solid $header-input-bdr;
      background-color: $base;
      border-radius: 5px;
      position: relative;
      &:after{
        font-family: 'Material Icons';
        content: "search";
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 1;
        color:$dark !important;
        opacity: 0.7;
        font-size:1.5rem;
        line-height: 1.8;
        pointer-events: none;
      }
      input{
        padding:6px 40px 6px 15px;
          font-size: 17.36px;
          letter-spacing: .5px;
      }
      input::placeholder{
          color:$dark !important;
          opacity: 0.5;
        }
  }
}
