/*------------------------------------------------------------
  5.1.2 footer
-----------------------------------------------------------*/
// Footer one style
.iron-footer-v1{
  .iron-footer-top{
    background-color: $primary;
  padding-bottom: 2rem;
  padding-top:1.5rem;
  }
  .footer-hr{
    margin: 0;
    border-bottom: 1px solid rgba($base, 0.2);
  }
  *{
    color:#000;
  }
  .footer-widget-title{
      h6{
        color:$grey;
        font-weight: $font-weight-base;        
        font-size: 1.5rem;
        text-align: center;
      }
  }
  .iron-footer-menu-list{
    padding: 0;
    text-align: center;
      .list-item{
          padding: 0 0 0.3125rem 0;
          margin-bottom: 0.625rem;
          span{
            color:$base;
            text-transform: capitalize;
            font-size:0.875rem;
          }
      }
  }
  .iron-footer-menu-list a{
    color:$grey;

  }
  .iron-footer-bottom{
    padding: 2.1875rem 0;
    background-color: $primary;
  }
}