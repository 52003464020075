/*------------------------------------------------------------
  5.1.1 headers
-----------------------------------------------------------*/

//header one styling
.iron-header-v1{
  box-shadow: none !important;
  background-color:$base;
  .iron-header-top{
    background-color: rgba($base,.05); 
  }
  .iron-header-bottom{
   min-height:64px;
  }
  .iron-header-menu{
    li{
      a{
        color: $grey;
        font-size: 1.3rem;
      font-weight: 500;
      }
    }
  }
}

