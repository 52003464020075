/*------------------------------------------------------------
    4.3 bg
----------------------------------------------------------*/

.bg-primary{
  background-color:$primary;
}
.bg-secondary{
  background-color:$grey;
}
.bg-grey{
  background-color:$grey2;
}
.bg-success{
  background-color:$success;
}
.bg-danger{
  background-color:$danger;
}
.bg-warning{
  background-color:$warning;
}
.bg-info{
  background-color:$info;
}
.bg-dark1{
  background-color:$black-light;
}
.bg-dark2{
  background-color:$black;
}
.bg-active{
  background-color:$active;
}
.bg-base{
  background-color: $base;
}

.bg-alice-blue{
  background-color: $alice-blue!important;  
}


