.bg-gradient-dark {
    // background-image: linear-gradient(310deg, #217B96 0%, rgb(0, 4, 29) 100%);
    // background-image: linear-gradient(310deg, #afccdc 0%, #5a84b6 100%);
    background-image: linear-gradient(310deg, $princess-perfume 0%, $claret 100%);
    
}

.pt-sm-8 {
    padding-top: 8rem !important;
}
.pb-5 {
    padding-bottom: 3rem !important;
}
.position-relative {
    position: relative !important;
}

.mt-n3 {
    margin-top: -1rem !important;
}
.w-100 {
    width: 100% !important;
}
.top-0 {
    top: 0 !important;
}
.position-absolute {
    position: absolute !important;
}

.text-start {
    text-align: left !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.text-white {
    color: #fff !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.rounded {
    border-radius: 40px;
}

.blur {
    box-shadow: inset 0px 0px 2px #fefefe;
    -webkit-backdrop-filter: saturate(200%) blur(30px);
    backdrop-filter: saturate(200%) blur(30px);
    background-color: rgba(255, 255, 255, 0.7) !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}
.end-0 {
    right: 0 !important;
}
.start-0 {
    left: 0 !important;
}
.top-0 {
    top: 0 !important;
}
.position-absolute {
    position: absolute !important;
}
.shadow {
    box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.17) !important;
}
.border{
    border: 1px solid #eee;
}


.rounded-20 {
    border-radius: 20px!important;
}

.mp-container{
    height: 18px;
    display: inline-block;
    border:  1px solid rgba(233, 214, 175, 0.8);
    padding: 18.5px 14px;
    border-radius: 4px;

    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0.75rem !important;
}