//---- Custom App Variables -------


@font-face {
        //font-family: 'Montserrat-Regular';
        //src: local('Montserrat-Regular'), url(../fonts/Montserrat-Medium.otf) format('truetype');
        font-family: 'Futura Md BT';
        src: local('Futura Md BT'), url(../fonts/futura/tt0142m_.ttf) format('truetype');        
    }

//Theme default colors:-

$logo: #e9d6af;

$primary: #e9d6af;
$secondary:#217B96;

$primary-text:#3b3e41;


$claret:#8c1637;
$violet-red:#fa479a;
$princess-perfume:#ff84d1;
$pigeon-post:#afccdc;
$alice-blue:#5a84b6;

$success: #28a745;
$danger:#DE3F3F; 
$warning:#ffc107;
$info:#17a2b8;

$transparent:transparent;
$dark-grey:rgba(0, 0, 0, 0.7);
$dark:rgba(0, 0, 0, 0.87);

$active:#e0729a;
$black:#000000;
$black-light:#212121;

$base:#fff;
$grey:#3E3F41;
$grey2:#f1f1f1;
$grey3:#aaa;
$border:#eceeef;
$border2:#eee;
//font-family:-
$roboto:'Futura Md BT', Futura Md BT;


//base style:-
$html-font-size: 18px;

//font-weight:-
$font-weight-light:100 !default;
$font-weight-base: 0 !default;
$font-weight-medium:300 !default;
$font-weight-bold: 500 !default;
$font-weight-italic:italic;

//body style:-
$body-bg:$base;
$body-color:$dark;
$font-size-body:1rem !default;//16px
$line-height:1 !default;

//heading font sizes:-
$font-size-h1: 3rem !default;//52px
$font-size-h2: 2.5rem !default;//45px
$font-size-h3: 1.4rem !default;//34px
$font-size-h4: 1.3rem !default;//24px
$font-size-h5: 1.25rem !default;//20px
$font-size-h6: 1rem !default;//16px

//heading line-height :-
$line-height-h1:3.75rem !default;//60px
$line-height-h2:3rem !default;//48px
$line-height-h3:2.5rem !default;//40px
$line-height-h4:2rem !default;//32px
$line-height-h5:1.75rem !default;//28px
$line-height-h6:1.5rem !default;//24px

//heading font color:-
$h1-font-color:$dark !default;//black
$h2-font-color:$dark !default;//black
$h3-font-color:$dark !default;//black
$h4-font-color:$dark !default;//black
$h5-font-color:$dark !default;//black
$h6-font-color:$dark !default;//black

//paragraph :-
$font-size-p:0.875rem !default;//14px
$line-height-p:1.25rem !default;//20px
$font-color-p:$dark-grey;

//lead
$font-size-lead:1.25rem !default;//20px
$line-height-lead: 1.5 !default;

//Page Title
$page-title-bg-image:url("../../../../assets/images/page-title-bar.jpg");
$page-title-padding:7rem 0;
$page-title-color:$base;

//box-shadow
$box-shadow:0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((
        0: 0,
        5: ($spacer * .3125),
        10: ($spacer * .625),
        15: ($spacer * .9375),
        20: ($spacer * 1.25),
        25: ($spacer * 1.5625),
        30: ($spacer * 1.875),
        40: ($spacer * 2.5),
        50: ($spacer * 3.125),
        60: ($spacer * 3.75)
  ),
        $spacers
);
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 600px,
        md: 960px,
        lg: 1280px,
        xl: 1920px
) !default;

$btn-bg:#E0E0E0;
$btn-danger: #f44336;
$btn-transparent-bdr:#d8d8d8;
$rating-default:#C8C8C8;
$rating:#edb867;
$slider-arrow-bg:#eef2f3;
$primary-overlay:#0e1b79;
$mega-menu-border:#d5d5d5;
$header-input-bdr:$border2;
$table-bdr:#e1e1e1;